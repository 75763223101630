import { GUIDE_SELECT_SETTLEMENT_ROUTE, GUIDE_SETTLEMENT_RATING_ROUTE } from 'routes/guide/list';

import useFullSelectedEmployee from './useFullSelectedEmployee.hook';

export const useSettlement = () => {
  const [selectedEmployee] = useFullSelectedEmployee();
  const hasCurrentSettlement = !!selectedEmployee?.currentSettlement?.id;
  const currentSettlementId = selectedEmployee?.currentSettlement?.id;

  const settlementRatingRoute = hasCurrentSettlement
    ? GUIDE_SETTLEMENT_RATING_ROUTE
    : GUIDE_SELECT_SETTLEMENT_ROUTE;

  return { hasCurrentSettlement, settlementRatingRoute, currentSettlementId };
};
