import { SurvyesQuestionsType, ValuesType } from 'gazprom-common-lib';
import { MORE_SURVEYS_ROUTE } from 'routes/more/list';
import { t } from 'tools/i18n';

import Header from 'containers/header';

import { OriginalObject, deserializeAnswers } from '../../../pages/surveys/surveys.utils';

export const headerChildren = (
  <>
    <Header.GoBack to={MORE_SURVEYS_ROUTE} />
    <Header.Label label={t('survey_filling_survey')} />
  </>
);

export const countFilledRequiredFields = (obj?: ValuesType, requiredFields?: string[]): number => {
  let counter = 0;

  try {
    if (obj && requiredFields) {
      requiredFields.forEach((field) => {
        const keyInObj = Object.keys(obj).find((el) => el.includes(field));
        if (keyInObj && Boolean(obj[keyInObj]) && typeof obj[keyInObj] !== 'undefined') {
          counter++;
        }
      });
    }
  } catch (e) {
    console.error(e);
    return 0;
  }

  return counter;
};

export const countRequiredQuestions = (array: SurvyesQuestionsType[]): string[] => {
  try {
    return array.reduce((arr, currQuestion) => {
      if ('is_required' in currQuestion && currQuestion.is_required) {
        arr.push(currQuestion.id.toString());
      }
      return arr;
    }, [] as string[]);
  } catch (e) {
    console.error(e);
    return [];
  }
};

const removeAdditionalProps = (obj: OriginalObject) => {
  try {
    Object.keys(obj).forEach((key) => {
      if (key.includes('additional')) {
        delete obj[key];
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const countAnswers = (obj: OriginalObject): number => {
  try {
    removeAdditionalProps(obj);
    const prepareAnswers = deserializeAnswers(obj).answer;
    const allAnswers = Object.values(prepareAnswers);
    const counter = allAnswers.filter(({ value }) => !Array.isArray(value) || value.length).length;

    return counter;
  } catch (e) {
    return 0;
  }
};
