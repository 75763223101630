import { QuestionTypes } from 'gazprom-common-lib';

export type OriginalObject = Record<string, string | number[]>;
type ExtraParamsType = {
  survey_id: string;
  params: {
    ap_employee_id: string;
    ap_anketa_link_id?: string;
    ap_link_id?: string;
    completedAnswerCount?: number;
  };
};
type ResultMatrixObject = Record<string, { value: Record<string, string[] | undefined> }>;
type ResultOtherObject = Record<string, { value: string | string[] }>;
type ResultScaleObject = Record<string, { value: Record<string, string> }>;
type SelectScaleObject = Record<string, { value: [string] }>;

export const deserializeAnswers = (
  originalObject: OriginalObject,
  extraParams?: ExtraParamsType,
) => {
  const matrix = matrixDeserialize(originalObject);
  const scale = scaleDeserialize(originalObject);
  const select = selectDeserialize(originalObject);
  const dropdown = dropdownDeserialize(originalObject);
  const other = otherQuestionsDeserialize(originalObject);

  return {
    survey_id: extraParams?.survey_id,
    params: extraParams?.params,
    answer: {
      ...matrix,
      ...scale,
      ...select,
      ...dropdown,
      ...other,
    },
  };
};

const matrixDeserialize = (originalObject: OriginalObject) => {
  const mainKey: QuestionTypes = 'matrix';
  const transformedObject: ResultMatrixObject = {};

  for (const key in originalObject) {
    if (key.includes(mainKey)) {
      const trimmedKey = key.replace(`${mainKey}.`, '');
      const [parentKey, childKey] = trimmedKey.split('.');

      if (!transformedObject[parentKey]) {
        transformedObject[parentKey] = { value: {} };
      }
      const stringValue = originalObject[key] ? originalObject[key].toString() : undefined;
      transformedObject[parentKey].value[childKey] = stringValue ? [stringValue] : undefined;
    }
  }

  for (const parentKey in transformedObject) {
    const hasUndefinedChild = Object.values(transformedObject[parentKey].value).some(
      (val) => typeof val === 'undefined',
    );

    if (hasUndefinedChild) {
      delete transformedObject[parentKey];
    }
  }

  return transformedObject;
};

const scaleDeserialize = (originalObject: OriginalObject): ResultScaleObject => {
  const mainKey: QuestionTypes = 'scale';
  const transformedObject: ResultScaleObject = {};

  for (const key in originalObject) {
    if (key.includes(mainKey)) {
      const trimmedKey = key.replace(`${mainKey}.`, '');
      const [parentKey, childKey] = trimmedKey.split('.');

      if (originalObject[key]) {
        transformedObject[parentKey] = {
          value: {
            [childKey]: originalObject[key].toString(),
          },
        };
      }
    }
  }

  return transformedObject;
};

const selectDeserialize = (originalObject: OriginalObject) => {
  const mainKey: QuestionTypes = 'select';
  const transformedObject: SelectScaleObject = {};

  for (const key in originalObject) {
    if (key.includes(mainKey) && !key.includes('multiselect')) {
      const trimmedKey = key.replace(`${mainKey}.`, '');

      if (originalObject[key]) {
        transformedObject[trimmedKey] = {
          value: [originalObject[key].toString()],
        };
      }
    }
  }

  return transformedObject;
};

const dropdownDeserialize = (originalObject: OriginalObject) => {
  const mainKey: QuestionTypes = 'dropdown';
  const transformedObject: SelectScaleObject = {};

  for (const key in originalObject) {
    if (key.includes(mainKey)) {
      const trimmedKey = key.replace(`${mainKey}.`, '');

      if (originalObject[key]) {
        transformedObject[trimmedKey] = {
          value: [originalObject[key].toString()],
        };
      }
    }
  }

  return transformedObject;
};

const otherQuestionsDeserialize = (originalObject: OriginalObject) => {
  const transformedObject: ResultOtherObject = {};
  const mainKeys: QuestionTypes[] = ['free', 'multiselect'];

  for (const key in originalObject) {
    if (originalObject[key] && mainKeys.some((mainKey) => key.includes(mainKey))) {
      const currentKey = key.split('.')[1];
      if (Array.isArray(originalObject[key])) {
        const array = (originalObject[key] as number[]).map((el) => el.toString());
        if (array.length > 0) {
          transformedObject[currentKey] = {
            value: array,
          };
        }
      } else {
        transformedObject[currentKey] = {
          value: originalObject[key].toString(),
        };
      }
    }
  }

  return transformedObject;
};
