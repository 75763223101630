import { createSlice } from '@reduxjs/toolkit';
import { CommunityDiscussionCommentType, PaginationPropsType } from 'gazprom-common-lib';

export const SLICE_NAME = 'communityCommentsSlice';

export type CommentWithChildren = CommunityDiscussionCommentType & { children?: CommunityDiscussionCommentType[] }
export type CommunityCommentsSliceState = PaginationPropsType & {
  comments: CommentWithChildren[];
};

const DEFAULT_PAGINATION = { page: 1, size: 40 };

const initialState: CommunityCommentsSliceState = {
  comments: [],
  page: 1,
  size: 100
};

export const communityCommentsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPaginationComments(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = 100;
      state.comments = [];
    },
    addComments(state, action) {
      function buildHierarchy(items: CommentWithChildren[]): CommentWithChildren[] {
        const itemMap = new Map<string, CommentWithChildren>();

        items.forEach((item) => {
          itemMap.set(item.id, { ...item, children: [] });
        });

        const result: CommunityDiscussionCommentType[] = [];

        items.forEach((item) => {
          if (item.parentId) {
            const parent = itemMap.get(item.parentId);
            if (parent) {
              parent.children?.push(itemMap.get(item.id)!);
            }
          } else {
            result.push(itemMap.get(item.id)!);
          }
        });

        return result;
      }
      state.comments = state.comments.concat(buildHierarchy(action.payload));
    },
    addComment(state, action) {
      if (action.payload.parentId) {
        state.comments = state.comments.map((community) => {
          if (community.id === action.payload.parentId) {
            return {
              ...community,
              children: community.children ? community.children.concat(action.payload) : [action.payload],
            };
          }

          return community;
        });
      } else {
        state.comments = state.comments.concat(action.payload);
      }
    },
    removeComment(state, action: { payload: CommunityDiscussionCommentType }) {
      if (action.payload.parentId) {

        state.comments = state.comments.map((community) => {
          if (community.id === action.payload.parentId) {
            return {
              ...community,
              children: community.children?.filter(child => child.id !== action.payload.id),
            };
          }

          return community;
        });
      } else {
        state.comments = state.comments.filter(comment => comment.id !== action.payload.id);
      }
    },
    updateComment(state, action: { payload: CommunityDiscussionCommentType }) {
      if (action.payload.parentId) {
        state.comments = state.comments.map((community) => {
          if (community.id === action.payload.parentId) {
            return {
              ...community,
              children: community.children?.map(child => {
                if (child.id === action.payload.id) {
                  return {
                    ...child, comment: action.payload.comment
                  }
                }
                return child;
              }),
            };
          }

          return community;
        });
      } else {
        state.comments = state.comments.map(comment => {
          if (comment.id === action.payload.id) {
            return {
              ...comment,
              comment: action.payload.comment
            }
          }
          return comment;
        });
      }
    },
    resetComments(state) {
      state.comments = [];
    },
    resetPagination(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
    },
    addPage(state) {
      state.page = state.page + 1;
    },
    changeSubscription(state, action) {
      state.comments = state.comments.map((community) => {
        if (community.id === action.payload.id) {
          return {
            ...community,
            status: action.payload.status,
          };
        }

        return community;
      });
    },
  },
});

export const {
  addPage,
  addComment,
  addComments,
  resetPaginationComments,
  resetComments,
  removeComment,
  resetPagination,
  changeSubscription,
  updateComment,
} = communityCommentsSlice.actions;

export default communityCommentsSlice.reducer;
