import { lazy } from 'react';

import ProfileRating from 'pages/profile/profile-rating';

import { RouteConfig } from '../routes';
import {
  PROFILE_AUTH_CHANGE_PIN_ROUTE,
  PROFILE_AUTH_ROUTE,
  PROFILE_COMPANY_ROUTE,
  PROFILE_EMPLOYEE_VACATION_ROUTE,
  PROFILE_MY_SALARY_PAYSLIP_ROUTE,
  PROFILE_MY_SALARY_ROUTE,
  PROFILE_PERSONAL_DATA_ROUTE,
  PROFILE_RATING_ROUTE,
  PROFILE_ROUTE,
  PROFILE_SCHEDULE_ROUTE,
  PROFILE_VACATION_AGREED_WITH_ID_ROUTE,
  PROFILE_VACATION_EMPLOYEES_ROUTE,
  PROFILE_VACATION_ROUTE,
} from './list';

const MySalary = lazy(() => import('pages/my-salary'));
const Profile = lazy(() => import('pages/profile'));
const ProfileAuth = lazy(() => import('pages/profile/profile-auth'));
const ProfileAuthChangePin = lazy(
  () => import('pages/profile/profile-auth/profile-auth-change-pin'),
);
const ProfileCompany = lazy(() => import('pages/profile/profile-company'));
const ProfilePersonalData = lazy(() => import('pages/profile/profile-personal-data'));
const ProfileSchedule = lazy(() => import('pages/profile/profile-schedule'));
const Vacation = lazy(() => import('pages/vacation'));
const Agreed = lazy(() => import('pages/vacation/containers/agreed'));
const VacationEmployee = lazy(() => import('pages/vacation/vacation-employee'));
const VacationEmployees = lazy(() => import('pages/vacation/vacation-employees'));

export const profileRoutes: RouteConfig[] = [
  {
    path: PROFILE_ROUTE,
    element: <Profile />,
  },
  {
    path: PROFILE_COMPANY_ROUTE,
    element: <ProfileCompany />,
  },
  {
    path: PROFILE_SCHEDULE_ROUTE,
    element: <ProfileSchedule />,
  },
  {
    path: PROFILE_MY_SALARY_ROUTE,
    element: <MySalary />,
  },
  {
    path: PROFILE_MY_SALARY_PAYSLIP_ROUTE,
    element: <MySalary />,
  },
  {
    path: PROFILE_PERSONAL_DATA_ROUTE,
    element: <ProfilePersonalData />,
  },
  {
    path: PROFILE_VACATION_ROUTE,
    element: <Vacation />,
  },
  {
    path: PROFILE_AUTH_ROUTE,
    element: <ProfileAuth />,
  },
  {
    path: PROFILE_AUTH_CHANGE_PIN_ROUTE,
    element: <ProfileAuthChangePin />,
  },
  {
    path: PROFILE_VACATION_EMPLOYEES_ROUTE,
    element: <VacationEmployees />,
  },
  {
    path: PROFILE_VACATION_AGREED_WITH_ID_ROUTE,
    element: <Agreed />,
  },
  {
    path: PROFILE_EMPLOYEE_VACATION_ROUTE,
    element: <VacationEmployee />,
  },
  {
    path: PROFILE_RATING_ROUTE,
    element: <ProfileRating />,
  },
];
