import { FC } from 'react';

import cn from 'classnames';
import { ArticleFullType } from 'gazprom-common-lib';
import DOMPurify from 'isomorphic-dompurify';
import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import s from './Contacts.module.scss';

interface ContactsProps {
  handleDownload: () => void;
  article?: ArticleFullType;
}

const Contacts: FC<ContactsProps> = (props) => {
  const { handleDownload, article } = props;

  if (!article) return null;

  return (
    <>
      <Flex
        gap="8"
        className={s.download}
        align="center"
        justify="space-between"
        onClick={handleDownload}>
        <Flex gap="8" align="center">
          <div className={cn(s.icon)}>
            <Icon name="docTypeEmpty" />
          </div>
          <Typography.Text size="14" weight="500">
            {t('profile_rating_download_contacts')}
          </Typography.Text>
        </Flex>
        <Icon name="download" color="var(--color-primary-6)" />
      </Flex>
      <div
        className={s.contacts}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(article.text),
        }}
      />
    </>
  );
};

export default Contacts;
