import { prepareQueryParams } from 'gazprom-common-lib';

import initialApiService from 'services/initialApiService';

import {
  GetEmployeeSurveysRequest,
  GetEmployeeSurveysResponse,
  GetQuestionsBySurveyIdRequest,
  GetQuestionsBySurveyIdResponse,
  GetSurveyAnswersResponse,
  GetSurveyByIdRequest,
  GetSurveyByIdResponse,
  GetSurveyStatusRequest,
  GetSurveyStatusResponse,
  GetSurveysAnswersRequest,
  SendSurveyAnswerRequest,
} from './surveyApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { anketaUrl, anketologUrl, mapSurveyPages, personUrl } from './utils';

export const surveyApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeSurveys: builder.query<GetEmployeeSurveysResponse, GetEmployeeSurveysRequest>({
      query: (params) => ({
        url: anketaUrl(prepareQueryParams(params)),
      }),
      providesTags: [SERVICE_TAGS.GET_SURVEYS],
    }),
    getAllSurveys: builder.query<GetEmployeeSurveysResponse, void>({
      query: () => ({
        url: personUrl(),
      }),
      providesTags: [SERVICE_TAGS.GET_SURVEYS],
    }),
    getSurveyById: builder.query<GetSurveyByIdResponse, GetSurveyByIdRequest>({
      query: (params) => ({
        url: anketologUrl('manage/info'),
        method: 'POST',
        body: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
      }),
    }),
    getQuestionsBySurveyId: builder.query<
      GetQuestionsBySurveyIdResponse,
      GetQuestionsBySurveyIdRequest
    >({
      query: (params) => ({
        url: anketologUrl('revision/info'),
        method: 'POST',
        body: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
      }),
      transformResponse: (response: GetQuestionsBySurveyIdResponse) => {
        return mapSurveyPages(response);
      },
    }),
    sendSurveyAnswer: builder.mutation<void, SendSurveyAnswerRequest>({
      query: (params) => ({
        url: anketologUrl('answer/create'),
        method: 'POST',
        body: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SURVEYS, SERVICE_TAGS.GET_SURVEY_STATUS],
    }),
    getSurveyStatus: builder.query<GetSurveyStatusResponse, GetSurveyStatusRequest>({
      query: (params) => ({
        url: anketaUrl(`/info${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_SURVEY_STATUS],
    }),
    getSurveysAnswers: builder.mutation<GetSurveyAnswersResponse[], GetSurveysAnswersRequest>({
      query: (params) => ({
        url: anketologUrl('answer/list'),
        method: 'POST',
        body: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
      }),
    }),
  }),
});

export const {
  useGetEmployeeSurveysQuery,
  useGetAllSurveysQuery,
  useGetQuestionsBySurveyIdQuery,
  useGetSurveyByIdQuery,
  useSendSurveyAnswerMutation,
  useGetSurveyStatusQuery,
  useGetSurveysAnswersMutation,
} = surveyApiService;
