import { useEffect, useState } from 'react';

import cn from 'classnames';
import { ARTICLE_TYPE_ENUM } from 'gazprom-common-lib';
import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import { useLazyGetFullArticlesQuery } from 'services/article/articleApiService';

import { Flex, Icon, Tag, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './ProfileRating.module.scss';
import Contacts from './containers/contacts';
import Memo from './containers/memo';

const fixRating = (rating?: number | null) => {
  if (!rating) return null;

  if (rating <= 300) return 300;
  if (rating <= 700) return 700;
  if (rating <= 1000) return 1000;
  return null;
};

const TAGS = [
  { title: t('profile_rating_memo'), value: 'memo' },
  { title: t('common_contacts'), value: 'contacts' },
];

const ProfileRating = () => {
  const [activeTag, setActiveTag] = useState('memo');
  const [selectedEmployee] = useFullSelectedEmployee();
  const { handleOpenFile, loadingKey } = useOpenFileByUrl();
  const [getArticles, { data }] = useLazyGetFullArticlesQuery();

  useEffect(() => {
    if (selectedEmployee?.employer?.id)
      getArticles({
        page: 1,
        size: 1,
        employerId: selectedEmployee?.employer?.id,
        type: [ARTICLE_TYPE_ENUM.CONTACTS],
      });
  }, [getArticles, selectedEmployee?.employer?.id]);

  const rating = fixRating(selectedEmployee?.person?.rating);

  const article = data?.items?.[0];

  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_ROUTE} useDefaultGoBack />
      <Header.Label label={t('profile_rating_title')} />
    </>
  );

  const handleDownloadContacts = () => {
    handleOpenFile({
      url: `${document.location.origin}${article?.media?.[0].link!}`,
      fileKey: 'contacts',
    });
  };

  const handleDownloadMemo = () => {
    handleOpenFile({
      url: `${document.location.origin}/rating_memo.pdf`,
      fileKey: 'rating_memo',
    });
  };

  const renderTag = (tag: { title: string; value: string }) => {
    const handleClick = () => {
      setActiveTag(tag.value);
    };

    return (
      <Tag
        type={activeTag === tag.value ? 'primary' : 'neutral'}
        key={tag.value}
        onClick={handleClick}
        className={s.tag}>
        {tag.title}
      </Tag>
    );
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          {!!rating && (
            <Card>
              <Flex fullWidth gap="8">
                <div className={cn(s.icon, s[`icon${rating}`])}>
                  <Icon name="ratingFilled" />
                </div>
                <Flex vertical>
                  <Flex gap="4">
                    <Typography.Title level={5}>
                      {t('profile_rating_your_title')}:{' '}
                    </Typography.Title>
                    <Typography.Title level={5} className={s.top}>
                      {t('profile_rating_top', {
                        rating,
                      })}
                    </Typography.Title>
                  </Flex>
                  <Typography.Text size="12" type="secondary">
                    {t(`profile_rating_${rating}`)}
                  </Typography.Text>
                </Flex>
              </Flex>
            </Card>
          )}
          <Card withPadding={false}>
            <Flex gap="4" className={s.tags} wrap="wrap">
              {TAGS.map(renderTag)}
            </Flex>
            {activeTag === 'memo' && <Memo handleDownload={handleDownloadMemo} />}
            {!!article && activeTag === 'contacts' && (
              <>
                {!!article.media?.[0].link && (
                  <Contacts handleDownload={handleDownloadContacts} article={article} />
                )}
              </>
            )}
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default ProfileRating;
