import { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import s from './Memo.module.scss';

interface MemoProps {
  handleDownload: () => void;
}

const Memo: FC<MemoProps> = (props) => {
  const { handleDownload } = props;

  return (
    <>
      <Flex
        gap="8"
        className={s.download}
        align="center"
        justify="space-between"
        onClick={handleDownload}>
        <Flex gap="8" align="center">
          <div className={cn(s.icon)}>
            <Icon name="docTypeEmpty" />
          </div>
          <Typography.Text size="14" weight="500">
            {t('profile_rating_download_memo')}
          </Typography.Text>
        </Flex>
        <Icon name="download" color="var(--color-primary-6)" />
      </Flex>
      <div className={s.memo}>
        <Typography.Title level={4}>
          Формирование рейтинга линейных ИТР (мастер СМР, прораб, старший прораб)
        </Typography.Title>
        <Typography.Text size="14">
          Рейтинг работника категории «Линейный ИТР» - комплексный показатель состояния сотрудника,
          для формирования которого используется совокупность объективных показателей и экспертных
          оценок.
        </Typography.Text>
        <Typography.Title level={5}>
          Рейтинг работника включает 8 критериев, максимальный результат – 10 баллов
        </Typography.Title>
        <Typography.Text size="14">
          Чем выше рейтинг работника – тем более широкий доступ к мотивационным программам он может
          получить (расширенный ДМС, поездка в санаторий, детские лагеря/Новогодние Ёлки и т.д.)
        </Typography.Text>
        <Typography.Title level={5}>Периодичность оценки</Typography.Title>
        <Flex vertical className={s.paddingLeft} gap="4">
          <Typography.Text size="14">
            — 2 раза в год (периоды с 1 по 30 июня и с 1 по 24 декабря).
          </Typography.Text>
          <Typography.Text size="14">
            — Оценка вновь принятого работника осуществляется по окончании периода испытательного
            срока, но не позднее 4-го месяца с момента трудоустройства в Общество.
          </Typography.Text>
          <Typography.Text size="14">
            — При использовании показателей, обновляемых чаще 1 раза в 6 месяцев, используется
            среднее арифметическое значение.
          </Typography.Text>
          <Typography.Text size="14">
            — Победителям и призёрам фестиваля профессий «СтройГерой» присваивается максимальный
            рейтинг (Устанавливается по окончании фестиваля, сроком на 1 год без пересмотра)
          </Typography.Text>
        </Flex>
        <Typography.Title level={5}>
          Средний показатель выполнения интенсивного задания ОВЗ Генподрядчика более 100% в течение
          полугода
        </Typography.Title>
        <Flex vertical className={s.paddingLeft} gap="4">
          <Typography.Text size="14">— 1 балл</Typography.Text>
          <Typography.Text size="14">— Каждые 6 мес.</Typography.Text>
          <Typography.Text size="14">
            — Отдел труда и заработной платы/ отдел мотивации компании работника
          </Typography.Text>
          <Typography.Text size="14">
            — Необходимо регулярное участие в ОВЗ Генподрядчика и ежемесячное выполнение ОВЗ на
            уровне не менее 100% для достижение высокого среднегодового показателя. Информацию об
            ОВЗ Генподрядчика можно получить в Отделе труда и заработной платы вашей компании
          </Typography.Text>
        </Flex>
        <Typography.Title level={5}>
          Интегральный балл по результатам комплексной оценки свыше 66-100% / 46-65%
        </Typography.Title>
        <Flex vertical className={s.paddingLeft} gap="4">
          <Typography.Text size="14">— 1 или 2 балла</Typography.Text>
          <Typography.Text size="14">— Каждые 6 мес.</Typography.Text>
          <Typography.Text size="14">
            — Управление по оценке персонала АО «Газстройпром»
          </Typography.Text>
          <Typography.Text size="14">
            — Необходимо ответственно проходить оценку профессиональных и личностных компетенций,
            направляемую 1 раз в 6 месяцев (2 разных тестирования в онлайн-формате) сотрудниками
            Управления по оценке персонала АО «Газстройпром». Рекомендуем честно и развёрнуто
            отвечать на вопросы тестирования, помня о прямом влиянии результатов на Ваш интегральный
            рейтинг!
          </Typography.Text>
        </Flex>
        <Typography.Title level={5}>
          Средний показатель подтверждения объемов и качества строительно-монтажных работ
          строительным контролем Заказчика более 96% (для мастера СМР, производителя работ)
        </Typography.Title>
        <Flex vertical className={s.paddingLeft} gap="4">
          <Typography.Text size="14">— 2 балла</Typography.Text>
          <Typography.Text size="14">— Каждые 3 мес.</Typography.Text>
          <Typography.Text size="14">
            — Отдел труда и заработной платы/ отдел мотивации компании работника
          </Typography.Text>
          <Typography.Text size="14">
            — Необходимо корректно заполнять чек-листы и вовремя сдавать отчетную документацию по
            выполненным работам. Контроль качества осуществляется Департаментом контроля качества,
            по результатам выставляется единый показатель готовности по всему проекту (для всех
            сотрудников проекта – одинаковый показатель)
          </Typography.Text>
        </Flex>
        <Typography.Title level={5}>
          Показатель коэффициент строительной готовности более 95% в соответствии с ежемесячным
          выполнением (для старших производителей работ)
        </Typography.Title>
        <Flex vertical className={s.paddingLeft} gap="4">
          <Typography.Text size="14">— 2 балла</Typography.Text>
          <Typography.Text size="14">— каждый мес.</Typography.Text>
          <Typography.Text size="14">
            — Отдел труда и заработной платы/ отдел мотивации компании работника
          </Typography.Text>
          <Typography.Text size="14">
            — Данные предоставляются по всему проекту (для всех сотрудников проекта – одинаковый
            показатель)
          </Typography.Text>
        </Flex>
        <Typography.Title level={5}>
          Показатель строительной готовности объекта в соответствии с установленным показателем по
          ГПР на последнюю дату отчетного периода (коллективный показатель)
        </Typography.Title>
        <Flex vertical className={s.paddingLeft} gap="4">
          <Typography.Text size="14">— 2,5 балла</Typography.Text>
          <Typography.Text size="14">— Каждые 6 мес.</Typography.Text>
          <Typography.Text size="14">— Выгрузка из ЕЦП</Typography.Text>
          <Typography.Text size="14">
            — Данные предоставляются по всему проекту (для всех сотрудников проекта – одинаковый
            показатель)
          </Typography.Text>
        </Flex>
        <Typography.Title level={5}>Оценка руководителя 4/3</Typography.Title>
        <Flex vertical className={s.paddingLeft} gap="4">
          <Typography.Text size="14">— 0,5 или 1 балла</Typography.Text>
          <Typography.Text size="14">— Каждые 6 мес.</Typography.Text>
          <Typography.Text size="14">
            — Управление по работе с персоналом компании работника
          </Typography.Text>
          <Typography.Text size="14">
            — Оценка руководителя выставляется Вашим линейным руководителем (Начальником участка/
            управления) и повлиять на нее напрямую нельзя. Однако, Вы можете наладить с ним
            коммуникацию – обращаться за советом, запрашивать обратную связь по результатам своей
            работы, консультироваться по карьерным возможностям и базовым вопросам
          </Typography.Text>
        </Flex>
        <Typography.Title level={5}>
          Наличие корпоративных наград за год, предшествующий отчетному
        </Typography.Title>
        <Flex vertical className={s.paddingLeft} gap="4">
          <Typography.Text size="14">— 0,5 балла</Typography.Text>
          <Typography.Text size="14">— каждые 12 мес.</Typography.Text>
          <Typography.Text size="14">
            — Управление по работе с персоналом компании работника
          </Typography.Text>
          <Typography.Text size="14">
            — Корпоративные награды присуждаются наиболее результативным, продуктивным работникам,
            которые преданы компании и регулярно демонстрируют высокие производственные результаты.
            Сотрудники, проявляющие инициативу по переводу на объекты Особого приоритета, регулярно
            выполняющие ОВЗ и соблюдающие графики вахтования, имеют высокие шансы на получение
            корпоративных наград
          </Typography.Text>
        </Flex>
        <Typography.Title level={5}>Стаж работы более 1 года/более 5 лет</Typography.Title>
        <Flex vertical className={s.paddingLeft} gap="4">
          <Typography.Text size="14">— 0,5 или 1 балла</Typography.Text>
          <Typography.Text size="14">— каждые 12 мес.</Typography.Text>
          <Typography.Text size="14">
            — Управление по работе с персоналом компании работника
          </Typography.Text>
          <Typography.Text size="14">
            — Стаж работы с возможностью перевода между объектами и/ или компаниями контура ГСП
            считается непрерывным
          </Typography.Text>
        </Flex>
      </div>
    </>
  );
};

export default Memo;
