import cn from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import { DOCUMENTS_ROUTE } from 'routes/documents/list';
import { GUIDE_ROUTE } from 'routes/guide/list';
import { HOME_ROUTE } from 'routes/home/list';
import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';

import { buildExpiredSelector } from 'selectors/appSlice.selector';

import { Icon, TIconsLiteral } from 'gazprom-ui-lib';

import { useAppSelector } from '../../store';
import s from './Navbar.module.scss';

export type RouteType = { to: string; icon: TIconsLiteral; label: string };

interface NavbarProps {
  className?: string;
  navbarConfig?: RouteType[];
}

const getClassName = ({ isActive }: { isActive: boolean }) => cn(s.link, { [s.active]: isActive });

// ! Might need later
// const guideConfig: { icon: TIconsLiteral; label: string } =
//   process.env.?.trim()
//     ? { icon: 'buildHero', label: 'guide_nav_bar_event' }
//     : { icon: 'gid', label: 'common_guide' };

const ROUTES: RouteType[] = [
  { icon: 'homeShine', label: 'common_main', to: HOME_ROUTE },
  { icon: 'documents', label: 'common_documents', to: DOCUMENTS_ROUTE },
  { icon: 'accountCircleFilled', label: 'common_profile', to: PROFILE_ROUTE },
  { icon: 'gid', label: 'guide_my_settlement', to: GUIDE_ROUTE },
  { icon: 'menuMore', label: 'common_more', to: '/more' },
];

const renderNavlink = (
  props: RouteType,
  handleClick: (e: React.MouseEvent<HTMLElement>) => void,
) => {
  const { to, icon, label } = props;

  return (
    <NavLink to={to} className={getClassName} key={`navlink__${to}__${icon}`} onClick={handleClick}>
      <div className={s.halo} />
      <Icon name={icon} />
      <span>{t(label)}</span>
    </NavLink>
  );
};

const Navbar = (props: NavbarProps) => {
  const { className, navbarConfig } = props;

  const routesToRender = navbarConfig?.length ? navbarConfig : ROUTES;

  const navigate = useNavigate();

  const buildExpired = useAppSelector(buildExpiredSelector);

  const handleClickExpired = (e: React.MouseEvent<HTMLElement>) => {
    if (buildExpired) {
      e.preventDefault();
      navigate('/error?code=n005');
    }
  };

  return (
    <nav className={cn(s.wrapper, className)}>
      {routesToRender.map((route) => {
        return renderNavlink(route, handleClickExpired);
      })}
    </nav>
  );
};

export default Navbar;
