import { t } from 'tools/i18n';
import { useSettlement } from 'utils/useSettlement.hook';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';

import s from './SettlementRating.module.scss';

const SettlementRating = () => {
  const { settlementRatingRoute, hasCurrentSettlement } = useSettlement();

  return (
    <Card.Navigate to={settlementRatingRoute} className={s.wrapper} withPadding>
      <Flex align="center" justify="space-between">
        <Flex gap="8" align="center">
          <Flex align="center" justify="center" className={s.ratingSvg}>
            <Icon name="ratingFilled" />
          </Flex>

          <Flex vertical align="start">
            <Typography.Text size="16" weight="500">
              {t('guide_settlement_rating_surveys')}
            </Typography.Text>

            <Typography.Text type="secondary">
              {t(
                hasCurrentSettlement
                  ? 'guide_settlement_rating_surveys_set_settlement'
                  : 'guide_settlement_rating_surveys_unset_settlement',
              )}
            </Typography.Text>
          </Flex>
        </Flex>

        <Icon name="forward" />
      </Flex>
    </Card.Navigate>
  );
};

export default SettlementRating;
