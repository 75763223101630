import { PropsWithChildren, forwardRef } from 'react';

import cn from 'classnames';

import s from './Card.module.scss';

interface CardProps extends PropsWithChildren {
  className?: string;
  withPadding?: boolean;
  id?: string;
  onClick?: () => void;
}

const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const { withPadding = true, children, className, id, onClick } = props;

  return (
    <div
      ref={ref}
      className={cn(s.wrapper, className, { [s.withPadding]: withPadding })}
      id={id}
      onClick={onClick}>
      {children}
    </div>
  );
});

export default Card;
