import { FC, useMemo } from 'react';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { Icon, Typography } from 'gazprom-ui-lib';

import { Divider } from 'components/divider';
import { Progress } from 'components/progress';
import SurveyLabel from 'components/survey-label';
import { LABEL_TYPES } from 'components/survey-label/surveyLabel';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import { countAnswers } from '../../../containers/anketolog/anketolog-single/anketologSingle.utils';
import s from './surveysCard.module.scss';

interface SurveysCardProps {
  anketaLinkId: string;
  anketologId: string;
  revisionId: number;
  name: string;
  isDividerVisible: boolean;
  isCompleted: boolean;
  completedDate: null | string;
  questionsCount: number;
  isMandatory: boolean;
  completedAnswerCount?: number;
}

const SurveysCard: FC<SurveysCardProps> = (props) => {
  const {
    anketologId,
    revisionId,
    anketaLinkId,
    name,
    isDividerVisible,
    isCompleted,
    completedDate,
    questionsCount,
    isMandatory,
    completedAnswerCount,
  } = props;
  const [storageValue] = useSecureStorage(
    `${SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SURVEY_PROGRESS_ID}_${anketologId}_${revisionId}_${anketaLinkId}`,
    '',
  );
  const link = `${anketologId}/${revisionId.toString()}/${anketaLinkId}`;
  const completedAnswers = completedAnswerCount ?? 0;

  const answers = useMemo(() => {
    return storageValue ? countAnswers(JSON.parse(storageValue)) : 0;
  }, [storageValue]);

  const renderLabel = () => {
    if (isCompleted) {
      return (
        <SurveyLabel type={LABEL_TYPES.COMPLETED}>
          {dayjs(completedDate).format('DD.MM.YYYY')}
        </SurveyLabel>
      );
    }

    if (isMandatory) {
      return <SurveyLabel type={LABEL_TYPES.MANDATORY} />;
    }
  };

  return (
    <>
      <Link to={link} className={s.surveyCard}>
        {renderLabel()}
        <div className={s.titleContainer}>
          <Typography.Title level={5}>{name}</Typography.Title>
          <Icon name="forward" />
        </div>
        {!!questionsCount && (
          <Progress current={isCompleted ? completedAnswers : answers} all={questionsCount} />
        )}
      </Link>
      {isDividerVisible && <Divider />}
    </>
  );
};

export default SurveysCard;
