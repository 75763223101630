export const GUIDE_ROUTE = '/guide';
export const GUIDE_BY_ID_ROUTE = `${GUIDE_ROUTE}/:id`;
export const GUIDE_MANAGEMENT_ROUTE = `${GUIDE_ROUTE}/management`;
export const GUIDE_EDUCATION_ROUTE = `${GUIDE_ROUTE}/education`;
export const GUIDE_SOCIAL_POLICY_ROUTE = `${GUIDE_ROUTE}/social-policy`;
export const GUIDE_SETTLEMENT_ROUTE = `${GUIDE_ROUTE}/guide-settlement`;
export const GUIDE_SETTLEMENT_RATING_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/rating`;
export const GUIDE_SETTLEMENT_SURVEY_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/survey`;
export const GUIDE_SETTLEMENT_SURVEY_BY_ID_ROUTE = `${GUIDE_SETTLEMENT_SURVEY_ROUTE}/:anketologId/:revisionId/:anketaLinkId`;
export const GUIDE_SETTLEMENT_REGIONS = `${GUIDE_SETTLEMENT_ROUTE}/regions`;
export const GUIDE_SETTLEMENT_BY_REGION = `${GUIDE_SETTLEMENT_ROUTE}/regions/:regionId`;
export const GUIDE_GUIDE_SETTLEMENT_POSTER_DETAILS_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/:posterId`;
export const getSettlementPosterWithIdWithIdRoute = (posterId: string) =>
  `${GUIDE_SETTLEMENT_ROUTE}/${posterId}`;
export const GUIDE_MEMO_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/memo`;
export const GUIDE_MAP_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/map`;
// TODO.Refactor GUIDE_MY_SETTLEMENTS_ROUTE нужен?
export const GUIDE_MY_SETTLEMENTS_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/my-settlements`;
export const GUIDE_QR_CODE_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/qr-code`;
export const GUIDE_SELECT_SETTLEMENT_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/select-settlement`;
export const GUIDE_IMPORTANT_CONTACTS_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/important-contacts`;
// TODO.Refactor legacy route - GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE?
export const GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/temporary-residential-camp`;
export const GUIDE_SOCIAL_POLICY_APPLICATIONS_ROUTE = `${GUIDE_SOCIAL_POLICY_ROUTE}/applications`;
export const GUIDE_SOCIAL_POLICY_CATEGORY_DETAILS_ROUTE = `${GUIDE_SOCIAL_POLICY_ROUTE}/:socialCategoryId`;
export const getSocialPolicyCategoryWithIdRoute = (socialCategoryId: string) =>
  `${GUIDE_SOCIAL_POLICY_ROUTE}/${socialCategoryId}`;
export const GUIDE_SOCIAL_POLICY_PROGRAM_DETAILS_ROUTE = `${GUIDE_SOCIAL_POLICY_CATEGORY_DETAILS_ROUTE}/:socialProgramId`;
export const GUIDE_SOCIAL_POLICY_PROGRAM_APPLY_ROUTE = `${GUIDE_SOCIAL_POLICY_PROGRAM_DETAILS_ROUTE}/:statementId/apply`;
export const GUIDE_SOCIAL_POLICY_PROGRAM_APPLY_FROM_DRAFT_ROUTE = `${GUIDE_SOCIAL_POLICY_CATEGORY_DETAILS_ROUTE}/:statementId/apply`;
export const GUIDE_ACTIVATE_SETTLEMENT_BY_ID = `${GUIDE_ROUTE}/activate/:settlementId`;

export const getSocialPolicyProgramWithIdRoute = (
  socialCategoryId: string,
  socialProgramId?: string,
) => `${getSocialPolicyCategoryWithIdRoute(socialCategoryId)}/${socialProgramId}`;

export const getSocialPolicyProgramApplyRoute = (
  socialCategoryId: string,
  socialProgramId: string,
  statementId: string,
) => `${getSocialPolicyProgramWithIdRoute(socialCategoryId, socialProgramId)}/${statementId}/apply`;

export const getSocialPolicyProgramApplyFromDraftRoute = (
  socialCategoryId: string,
  statementId?: string,
) => `${getSocialPolicyProgramWithIdRoute(socialCategoryId, statementId)}/apply`;

export const getSettlementsByRegionIdRoute = (regionId: number) =>
  `${GUIDE_SETTLEMENT_REGIONS}/${regionId}`;

export const getGuideSurveyRoute = (
  anketologId: string,
  revisionId: number,
  anketaLinkId: string,
) => `${GUIDE_SETTLEMENT_SURVEY_ROUTE}/${anketologId}/${revisionId}/${anketaLinkId}`;
